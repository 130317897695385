<template>
  <section class="info">
    <h1 class="display-4 mb-3">{{ name }}</h1>

    <p class="mb-3">
      <span v-if="textBefore">{{ textBefore }}&nbsp;</span>
      <vue-typer
        :text="text"
        :repeat="repeat"
        initial-action="erasing"
        :pre-type-delay="1000"
        :type-delay="90"
        :pre-erase-delay="1000"
        :erase-delay="70"
        erase-style="backspace"
        caret-animation="blink"
      ></vue-typer>
    </p>
  </section>
</template>

<script>
export default {
  name: "Info",
  props: {
    name: {
      type: String,
      require: true,
    },
    text: {
      type: Array,
      require: true,
    },
    textBefore: {
      type: String,
      require: false,
    },
    typerRepeat:{
      type: String,
      require: false,
    },
  },
  computed: {
    repeat: function () {
      if (this.typerRepeat == "Infinity" || isNaN(Number(this.typerRepeat))) {
        return Number("Infinity");
      } else {
        return parseInt(this.typerRepeat);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../style/common.scss";

.vue-typer {
  font-family: "Share Tech Mono", monospace;

  .custom.char {
    color: $green;
    background-color: $black;

    &:first-child {
      padding-left: 5px;
    }
    &:last-child {
      padding-right: 5px;
    }
  }

  .custom.caret {
    width: 7px;
    background-color: $green;
  }
}
</style>