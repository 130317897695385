<template>
  <section class="social">
    <div
      v-for="(element, key, index) in info"
      :key="key"
      :class="index !== 0 ? 'ml-2' : ''"
      class="d-inline"
    >
      <a :href="element.link" @click.prevent="clickCallback(key)">
        <i :class="element.icon" class="fa-2x"></i>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "SocialIcons",
  props: {
    info: {
      type: Object,
      require: true,
    },
  },
  methods: {
    clickCallback(key) {
      if (key == "mail") {
        this.email(this.info[key].encoded);
      } else {
        window.open(this.info[key].link, "_blank");
      }
    },
    email(encodedMail) {
      window.location = `mailto:${Buffer.from(encodedMail, "base64").toString(
        "ascii"
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;

  i {
    transition: 0.1s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>