<template>
  <div
    id="app"
    class="container d-flex flex-column justify-content-center text-center"
  >
    <!-- typerRepeat -> "Infinity" or number of repetitions "0" -->
    <Info :name="name" :text="skills" textBefore="I'm a" typerRepeat="Infinity"/>

    <SocialIcons :info="socialInfo" />
  </div>
</template>

<script>
import Info from "./components/Info";
import SocialIcons from "./components/SocialIcons";

export default {
  name: "App",
  components: {
    Info,
    SocialIcons,
  },
  data() {
    return {
      name: "Giorgio Zocchi",
      skills: [
        "Full-Stack Developer",
        "Dog Lover",
        "Front-End Developer",
        "Football Player",
        "Back-End Developer",
        "Mountaineer",
        "Web Developer",
      ],
      socialInfo: {
        github: {
          link: "https://github.com/gzocchi",
          icon: "fab fa-github-square",
        },
        linkedin: {
          link: "https://www.linkedin.com/in/gzocchi/",
          icon: "fab fa-linkedin",
        },
        mail: {
          link: "mailto:contact",
          icon: "fas fa-envelope-square",
          encoded: "ei5naW9yZ2lvLnpAZ21haWwuY29t",
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "style/common.scss";

#app {
  height: 100%;
}
</style>
